"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCategoryNext = exports.getCategoryInfo = exports.getCategoryChannel = exports.getArticleList = exports.getArticleDetail = void 0;
var _request = require("@/utils/request");
const getArticleList = (params = null) => {
  return (0, _request.get)(`article/category`, params);
};
exports.getArticleList = getArticleList;
const getArticleDetail = params => {
  return (0, _request.get)(`article/show`, params);
};
exports.getArticleDetail = getArticleDetail;
const getCategoryNext = (params = null) => {
  return (0, _request.get)(`article/category/next`, params);
};
exports.getCategoryNext = getCategoryNext;
const getCategoryInfo = (params = null) => {
  return (0, _request.get)(`article/category/info`, params);
};
exports.getCategoryInfo = getCategoryInfo;
const getCategoryChannel = (params = null) => {
  return (0, _request.get)(`article/category/channel`, params);
};
exports.getCategoryChannel = getCategoryChannel;