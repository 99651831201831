"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
var _article = require("@/api/article");
var _default = {
  name: 'CategoryGrid',
  data() {
    return {
      categoryList: []
    };
  },
  props: {
    parentId: {
      type: [Number, String],
      required: true,
      default() {
        return 0;
      }
    }
  },
  async created() {
    await this.fetchCategoryNext();
  },
  methods: {
    async fetchCategoryNext() {
      let params = {
        parent_id: this.parentId
      };
      try {
        const resp = await (0, _article.getCategoryNext)(params);
        if (Number(resp.code) === 0) {
          this.categoryList = resp.data.data_list;
        } else {
          return Promise.reject(new Error(resp.message));
        }
      } catch (e) {
        //this.$toast.fail(e.message);
      }
    },
    onCategoryClick(item) {
      if (Number(item.parent_id) !== 0) {
        this.$router.push({
          path: '/list',
          query: {
            id: item.id
          }
        });
      } else {
        this.$router.push({
          path: '/category',
          query: {
            id: item.id
          }
        });
      }
    }
  }
};
exports.default = _default;