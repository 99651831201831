"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  data() {
    return {
      zIndex: 999
    };
  },
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
    show() {
      return this.loading.show;
    }
  },
  watch: {
    $route: {
      handler(to, from) {
        this.fetchAppData();
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    fetchAppData() {
      this.$store.dispatch('app/fetchAppData').then(res => {
        let title;
        if (this.$route && this.$route.meta && this.$route.meta.title) {
          title = `${this.$route.meta.title}`;
        }
        if (res && res.app && res.app.title) {
          title += ` - ${res.app.title}`;
        }
        if (!title) {
          title = process.env.VUE_APP_TITLE;
        }
        document.title = title;
      });
    }
  },
  async created() {
    this.fetchAppData();
  }
};
exports.default = _default;