"use strict";

var _interopRequireDefault = require("/www/wwwroot/zhaopin.quanyoukeji.com/web/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.error.cause.js");
var _collection = require("@/api/collection");
var _Swiper = _interopRequireDefault(require("@/components/Swiper.vue"));
var _default = {
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      colList: [],
      page: 1,
      pageSize: 10,
      totalPage: 0,
      totalCount: 0
    };
  },
  components: {
    Swiper: _Swiper.default
  },
  async created() {
    this.$helper.loading(this);
    this.loading = true;
    await this.fetchCol();
  },
  methods: {
    async loadMore() {
      this.page += 1;
      this.$helper.loading(this);
      this.loading = true;
      await this.fetchCol();
    },
    async onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.dataList = [];
      this.$helper.loading(this);
      this.loading = true;
      this.page = 1;
      await this.fetchCol();
    },
    async fetchCol(params = null) {
      let def = {
        page: this.page,
        page_size: this.pageSize
      };
      if (params) {
        def = Object.assign(def, params);
      }
      try {
        const resp = await (0, _collection.getCollectionList)(def);
        this.$helper.loadingClose(this);
        this.loading = false;
        if (Number(resp.code) === 0) {
          const {
            data
          } = resp;
          if (data) {
            this.colList.push(...data.data_list);
            this.page = data.current_page;
            this.pageSize = data.page_size;
            this.totalCount = data.total_count;
            this.totalPage = data.total_page;
            for (let i in this.colList) {
              let url = this.colList[i].url;
              if (!url) {
                this.colList[i].url = '/collection/show?id=' + this.colList[i].id;
              }
            }
            this.refreshing = false;
            if (this.page >= this.totalPage || this.totalCount <= this.pageSize) {
              this.finished = true;
            } else {
              this.finished = false;
            }
          } else {
            this.colList = [];
            this.page = 1;
            this.pageSize = 10;
            this.totalCount = 0;
            this.totalPage = 0;
            return Promise.reject(new Error(resp.message));
          }
        }
      } catch (e) {
        console.log(e);
        this.$helper.loadingClose(this);
        this.loading = false;
        this.finished = true;
        this.colList = [];
        this.page = 1;
        this.totalPage = 0;
        this.totalCount = 0;
      }
    }
  }
};
exports.default = _default;