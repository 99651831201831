"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.error.cause.js");
var _article = require("@/api/article");
var _default = {
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      dataList: [],
      page: 1,
      pageSize: 3,
      totalPage: 0,
      totalCount: 0,
      cateId: 0,
      category: null
    };
  },
  async created() {
    this.$helper.loading(this);
    this.loading = true;
    this.cateId = this.$helper.getQuery(this);
    if (!this.cateId) {
      this.$helper.alert('分类id不能为空', () => {
        this.$router.go(-1);
      });
      return false;
    }
    await this.fetchData();
  },
  methods: {
    async loadMore() {
      this.page += 1;
      this.$helper.loading(this);
      this.loading = true;
      await this.fetchData();
    },
    async onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.dataList = [];
      this.$helper.loading(this);
      this.loading = true;
      this.page = 1;
      await this.fetchData();
    },
    async fetchData() {
      try {
        const resp = await (0, _article.getCategoryChannel)({
          cid: this.cateId,
          page: this.page,
          page_size: this.pageSize
        });
        this.$helper.loadingClose(this);
        this.loading = false;
        if (Number(resp.code) === 0) {
          const {
            data
          } = resp;
          if (data) {
            if (this.$helper.len(data.data_list) <= 0) {
              this.finished = true;
              return;
            }
            this.category = data.category_info;
            this.dataList.push(...data.data_list);
            this.page = data.current_page;
            this.pageSize = data.page_size;
            this.totalCount = data.total_count;
            this.totalPage = data.total_page;
            for (let i in this.dataList) {
              let url = this.dataList[i].url;
              if (!url) {
                this.dataList[i].url = '/show?id=' + this.dataList[i].id;
              }
            }
            this.refreshing = false;
            if (this.page >= this.totalPage || this.totalCount <= this.pageSize) {
              this.finished = true;
            } else {
              this.finished = false;
            }
          } else {
            this.finished = true;
            this.dataList = [];
            this.page = 1;
            this.totalPage = 0;
            this.totalCount = 0;
            return;
          }
        } else {
          return Promise.reject(new Error(resp.message));
        }
      } catch (e) {
        this.$helper.loadingClose(this);
        this.loading = false;
        this.finished = true;
        this.dataList = [];
        this.page = 1;
        this.totalPage = 0;
        this.totalCount = 0;
      }
    }
  }
};
exports.default = _default;