"use strict";

var _interopRequireDefault = require("/www/wwwroot/zhaopin.quanyoukeji.com/web/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dayjs = _interopRequireDefault(require("dayjs"));
var _default = {
  dayJs(params = null) {
    if (!params) {
      return (0, _dayjs.default)();
    } else {
      return (0, _dayjs.default)(params);
    }
  },
  timestamp(params = null) {
    return this.dayJs(params).unix();
  },
  dateDisplay(timestamp, year = '-', month = '-', day = '') {
    return this.dayJs(timestamp * 1000).format('YYYY' + year + 'MM' + month + 'DD' + day);
  },
  dateTimeDisplay(timestamp, year = '-', month = '-', day = '', h = ':', m = ':', s = '') {
    return this.dayJs(timestamp * 1000).format('YYYY' + year + 'MM' + month + 'DD HH' + h + 'mm' + m + 'ss' + s);
  },
  date(year = '-', month = '-', day = '') {
    return this.dayJs().format('YYYY' + year + 'MM' + month + 'DD' + day);
  },
  dateTime(year = '-', month = '-', day = '', h = ':', m = ':', s = '') {
    this.dayJs().format('YYYY' + year + 'MM' + month + 'DD HH' + h + 'mm' + m + 'ss' + s);
  },
  dateWithoutDay(year = '-', month = '') {
    return this.dayJs().format('YYYY' + year + 'MM' + month);
  },
  afterDayDate(num, year = '-', month = '-', day = '') {
    return this.dayJs().add(num, 'day').format('YYYY' + year + 'MM' + month + 'DD' + day);
  },
  beforeDayDate(num, year = '-', month = '-', day = '') {
    return this.dayJs().subtract(num, 'day').format('YYYY' + year + 'MM' + month + 'DD' + day);
  },
  diff(startTimestamp, endTimestamp) {
    // 将时间字符串变成 毫秒时间戳
    const startTime = this.dayJs(startTimestamp * 1000) - 0;
    const endTime = this.dayJs(endTimestamp * 1000) - 0;
    const deltaMs = Math.abs(endTime - startTime);
    // 计算出相差天数
    const days = Math.floor(deltaMs / (24 * 3600 * 1000));
    // 计算天数后剩余的毫秒数
    const leave1 = deltaMs % (24 * 3600 * 1000);
    // 计算出小时数
    const hours = Math.floor(leave1 / (3600 * 1000));
    // 计算小时数后剩余的毫秒数
    const leave2 = leave1 % (3600 * 1000);
    // 计算相差分钟数
    const minutes = Math.floor(leave2 / (60 * 1000));
    // 计算分钟数后剩余的毫秒数
    const leave3 = leave2 % (60 * 1000);
    // 计算相差秒数
    const seconds = Math.round(leave3 / 1000);
    return {
      days,
      hours,
      minutes,
      seconds
    };
  },
  toTimestamp(str) {
    // 把年月日换成"-"
    str = str.replace('年', '-');
    str = str.replace('月', '-');
    str = str.replace('日', '');
    str = str.replace('时', ':');
    str = str.replace('分', ':');
    str = str.replace('秒', '');
    return this.dayJs(str).unix();
  },
  /**
   * 毫秒转换友好的显示格式
   * 输出格式：21小时前
   * i为timestamp
   * @param  {[type]} time [description]
   * @return {[type]}      [description]
   */
  niceTime(i) {
    return this.dateTimeDisplay(i);
    if (!i || i < 0) {
      return '刚刚';
    }
    let b = parseInt(new Date().getTime() / 1000);
    let f = b - i;
    if (f < 0) {
      f = 0;
    }
    let e = f % 60;
    let a = parseInt(f % 3600 / 60);
    let c = parseInt(f % (3600 * 24) / 3600);
    let g = parseInt(f / (3600 * 24));
    if (g) {
      if (g <= 15) {
        return g + '天前';
      } else {
        return this.dateTimeDisplay(i);
      }
    } else {
      if (c) {
        return c + '小时前';
      } else {
        if (a) {
          return a + '分钟前';
        } else {
          if (e) {
            return e + '秒前';
          } else {
            return '刚刚';
          }
        }
      }
    }
  }
};
exports.default = _default;