"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "app"
    }
  }, [_c("router-view"), _c("van-overlay", {
    attrs: {
      "z-index": _vm.zIndex,
      show: _vm.show
    }
  }, [_c("div", {
    staticClass: "loading-wrapper",
    on: {
      click: function ($event) {
        $event.stopPropagation();
      }
    }
  }, [_vm.loading && _vm.loading.color ? _c("van-loading", {
    attrs: {
      color: _vm.loading.color,
      vertical: ""
    }
  }, [_vm._v(_vm._s(_vm.loading.text) + " ")]) : _c("van-loading", {
    attrs: {
      vertical: ""
    }
  }, [_vm._v(_vm._s(_vm.loading.text))])], 1)])], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;