"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLastErrorMessage = getLastErrorMessage;
exports.isAlphaNumber = isAlphaNumber;
exports.isAlphaNumberLine = isAlphaNumberLine;
exports.isBankNo = isBankNo;
exports.isDate = isDate;
exports.isDateTime = isDateTime;
exports.isEmail = isEmail;
exports.isExternalLink = isExternalLink;
exports.isFloat = isFloat;
exports.isFloatPercent = isFloatPercent;
exports.isGTZeroInteger = isGTZeroInteger;
exports.isInteger = isInteger;
exports.isMobile = isMobile;
exports.isMoney = isMoney;
exports.isSimpleIdCard = isSimpleIdCard;
//验证类
const validatorBase = {
  message: null,
  rules: {
    pInteger: [/(^[1-9]\d*$)/, "请填写正整数"],
    integer: [/^\d+$/, "请填写数字"],
    letter: [/^[a-z]+$/i, "请填写字母"],
    date: [/^\d{4}-\d{2}-\d{2}$/, "请填写有效的日期，格式:yyyy-mm-dd"],
    dateTime: [/^(\d{4})\-(\d{2})\-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/, "请填写有效的日期时间，格式:yyyy-mm-dd hh:mm:ss"],
    time: [/^([01]\d|2[0-3])(:[0-5]\d){1,2}$/, "请填写有效的时间，00:00到23:59之间"],
    email: [/^[\w\+\-]+(\.[\w\+\-]+)*@[a-z\d\-]+(\.[a-z\d\-]+)*\.([a-z]{2,4})$/i, "请填写有效的邮箱"],
    url: [/^(https?|s?ftp):\/\/\S+$/i, "请填写有效的网址"],
    IDCard: [/^\d{6}(19|2\d)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)?$/, "请填写正确的身份证号码"],
    simpleIdCard: [/^[1-9]{1}[0-9]{14}$|^[1-9]{1}[0-9]{16}([0-9]|[xX])$/, "请填写正确的身份证号码"],
    tel: [/^(?:(?:0\d{2,3}[\- ]?[1-9]\d{6,7})|(?:[48]00[\- ]?[1-9]\d{6}))$/, "请填写有效的电话号码"],
    mobile: [/^1[3-9]\d{9}$/, "请填写有效的手机号"],
    zipCode: [/^\d{6}$/, "请检查邮政编码格式"],
    cn: [/^[\u0391-\uFFE5]+$/, "请填写中文字符"],
    password: [/^[\S]{6,16}$/, "请填写6-16位字符，不能包含空格"],
    float: [/^[-\+]?\d+(\.\d+)?$/, '只能包含数字、小数点等字符'],
    number: [/(^[-\+]?\d+$) | (^[-\+]?\d+(\.\d+)?$)/, '请填写数字'],
    money: [/(^\-\d+$)|(^\-\d+\.\d{1,2}$)|(^\d+$)|(^\d+\.\d{1,2}$)/, '请填有效金额'],
    bankNo: [/^([1-9]{1})(\d{15}|\d{16}|\d{18})$/, '请填写有效的银行卡号'],
    alphaNumber: [/^[a-zA-Z0-9]+$/, '只能填写字母和数字'],
    alphaNumberLine: [/^[A-Za-z0-9\-_]*$/, '只能填写字母、数字、横线和下划线']
  },
  match(value, array) {
    let regx = array[0];
    let re = eval(regx);
    if (!re.exec(value)) {
      validatorBase.message = array[1];
      return false;
    }
    return true;
  },
  getMessage() {
    return validatorBase.message;
  }
};

/**
 * 链接地址
 * @param path
 * @returns {boolean}
 */
function isExternalLink(path) {
  return /^(http?:|https?:|mailto:|tel:)/.test(path);
}

/**
 * 手机号
 * @param mobile
 * @returns {boolean}
 */
function isMobile(mobile) {
  return mobile && validatorBase.match(mobile, validatorBase.rules.mobile);
}

/**
 * 电子邮件
 * @param value
 * @returns {*|boolean}
 */
function isEmail(value) {
  return value && validatorBase.match(value, validatorBase.rules.email);
}
/**
 * 大于0的数字
 * @param value
 * @returns {boolean}
 */
function isGTZeroInteger(value) {
  return validatorBase.match(value, validatorBase.rules.pInteger);
}

/**
 * 整型数字
 * @param value
 * @returns {boolean}
 */
function isInteger(value) {
  return validatorBase.match(value, validatorBase.rules.integer);
}

/**
 * 金额格式验证
 * @param value
 * @returns {boolean}
 */
function isMoney(value) {
  return validatorBase.match(value, validatorBase.rules.money);
}

/**
 * 是否为浮点型数字
 * @param value
 * @returns {boolean}
 */
function isFloat(value) {
  return validatorBase.match(value, validatorBase.rules.float);
}

/**
 * 百分比数值验证，如：99.12
 * @param value
 * @returns {boolean}
 */
function isFloatPercent(value) {
  if (!validatorBase.match(value, validatorBase.rules.float)) {
    return false;
  }
  if (value < 0.00 || value > 100.00) {
    validatorBase.message = '值必须在0.00到100.00之间';
    return false;
  }
  return true;
}

/**
 * 验证是否为日期+时间，如2020-07-08 12:34:34
 * @param value
 * @returns {boolean}
 */
function isDateTime(value) {
  return validatorBase.match(value, validatorBase.rules.dateTime);
}

/**
 * 验证是否为日期，如2020-07-08
 * @param value
 * @returns {boolean}
 */
function isDate(value) {
  return validatorBase.match(value, validatorBase.rules.date);
}

/**
 * 验证是否为字母+数字
 * @param value
 * @returns {boolean}
 */
function isAlphaNumber(value) {
  return validatorBase.match(value, validatorBase.rules.alphaNumber);
}

/**
 * 验证是否为字母+数字+横线和下划线
 * @param value
 * @returns {boolean}
 */
function isAlphaNumberLine(value) {
  return validatorBase.match(value, validatorBase.rules.alphaNumberLine);
}

/**
 * 简单的身份证验证
 * @param value
 * @returns {*|boolean}
 */
function isSimpleIdCard(value) {
  return value && !validatorBase.match(value, validatorBase.rules.simpleIdCard);
}

/**
 * 银行卡号验证
 * @param value
 * @returns {*|boolean}
 */
function isBankNo(value) {
  return value && validatorBase.match(value, validatorBase.rules.bankNo);
}

/**
 * 获取最后一次的错误信息
 * @returns {null}
 */
function getLastErrorMessage() {
  return validatorBase.getMessage();
}