"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const getters = {
  userId: state => state.auth.userId,
  loading: state => state.loading,
  tabbar: state => state.tabbar,
  app: state => {
    var _state$app$appData;
    return (_state$app$appData = state.app.appData) === null || _state$app$appData === void 0 ? void 0 : _state$app$appData.app;
  },
  wxoa: state => {
    var _state$app$appData2;
    return (_state$app$appData2 = state.app.appData) === null || _state$app$appData2 === void 0 ? void 0 : _state$app$appData2.wxoa;
  }
};
var _default = getters;
exports.default = _default;