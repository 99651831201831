"use strict";

var _interopRequireDefault = require("/www/wwwroot/zhaopin.quanyoukeji.com/web/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
var _article = require("@/api/article");
var _time = _interopRequireDefault(require("@/utils/time"));
var _default = {
  data() {
    return {
      id: null,
      row: null,
      timeHelper: _time.default
    };
  },
  async created() {
    this.id = this.$helper.getQuery(this);
    if (!this.id) {
      this.$helper.alert('内容id不能为空', () => {
        this.$router.go(-1);
      });
      return false;
    }
    await this.fetchDetail();
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onClickRight() {},
    async fetchDetail() {
      this.$helper.loading(this);
      try {
        const resp = await (0, _article.getArticleDetail)({
          id: this.id
        });
        this.$helper.loadingClose(this);
        if (Number(resp.code) === 0) {
          this.row = resp.data;
        } else {
          return Promise.reject(new Error(resp.message));
        }
      } catch (e) {
        this.$helper.loadingClose(this);
        this.$toast.fail(e.message);
      }
    }
  }
};
exports.default = _default;