"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _setting = require("@/api/setting");
var _default = {
  namespaced: true,
  state: {
    appData: null
  },
  mutations: {
    SET_APP_DATA: (state, data) => {
      state.appData = data;
    },
    CLEAN_APP_DATA: (state, data) => {
      state.appData = '';
    }
  },
  actions: {
    fetchAppData({
      commit
    }, params) {
      return new Promise((resolve, reject) => {
        (0, _setting.getSettingApp)(params).then(res => {
          if (Number(res['code']) !== 0) {
            return reject(res);
          }
          if (!res.data) {
            return reject(res);
          }
          let data = res.data.data_list;
          commit('SET_APP_DATA', data);
          resolve(data);
        }).catch(err => {
          reject(err);
        });
      });
    }
  }
};
exports.default = _default;