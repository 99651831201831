"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAdPlaceInfo = void 0;
var _request = require("@/utils/request");
/**
 * 暂时废弃
 */
const getAdPlaceInfo = params => {
  return (0, _request.get)(`ad/place`, params);
};
exports.getAdPlaceInfo = getAdPlaceInfo;