"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("swiper"), _c("div", {
    staticClass: "blank"
  }), _vm.$helper.len(_vm.colList) > 0 ? _c("van-pull-refresh", {
    on: {
      refresh: _vm.onRefresh
    },
    model: {
      value: _vm.refreshing,
      callback: function ($$v) {
        _vm.refreshing = $$v;
      },
      expression: "refreshing"
    }
  }, [_c("div", {
    staticClass: "van-list"
  }, _vm._l(_vm.colList, function (row) {
    return _c("div", {
      staticClass: "van-cell"
    }, [_c("div", {
      staticClass: "van-cell__title"
    }, [_c("a", {
      attrs: {
        href: row.url
      }
    }, [_vm._v(_vm._s(row.title))]), _c("span", {
      staticStyle: {
        "padding-left": "5px"
      }
    }, [_vm._v(_vm._s(row.area))])])]);
  }), 0), !_vm.finished ? _c("div", {
    staticClass: "load-more text-center",
    on: {
      click: _vm.loadMore
    }
  }, [_vm.loading ? _c("van-loading", {
    attrs: {
      size: "24px"
    }
  }, [_vm._v("加载中...")]) : [_vm._v("点击加载更多...")]], 2) : _c("div", {
    staticClass: "load-more text-center"
  }, [_vm._v("没有更多了")])]) : _vm._e()], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;