"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _area = require("@/api/area");
const DEFAULT_AREA_LIST = {
  province_list: {},
  city_list: {},
  county_list: {}
};
var _default = {
  name: "AreaSelector",
  props: {
    label: {
      type: String,
      default() {
        return '选择地区';
      }
    }
  },
  data() {
    return {
      areaList: {
        ...DEFAULT_AREA_LIST
      },
      areaSelected: {},
      areaLabel: this.label,
      areaPopupList: {},
      areaPopupValue: []
    };
  },
  async created() {
    await this.fetchAreaTree();
  },
  methods: {
    async fetchAreaTree() {
      (0, _area.getAreaTree)({
        level: 1,
        parent: 0
      }).then(res => {
        const {
          data
        } = res;
        this.areaList = {
          ...DEFAULT_AREA_LIST
        };
        let area, child;
        for (let i in data.data_list) {
          area = data.data_list[i];
          this.areaList.province_list[area.code] = area.name;
          if (this.$helper.len(area.children) > 0) {
            let prefix = area.code.toString().substring(0, 3);
            this.areaList.city_list[prefix] = '全部';
            for (let c in area.children) {
              child = area.children[c];
              this.areaList.city_list[child.code] = child.name;
            }
          }
        }
        this.areaPopupList = {
          ...this.areaList.province_list
        };
      });
    },
    fetchCityList(code) {
      let list = {};
      let prefix = code.toString().substring(0, 3),
        curPrefix;
      for (let i in this.areaList.city_list) {
        curPrefix = i.toString().substring(0, 3);
        if (curPrefix === prefix) {
          list[i] = this.areaList.city_list[i];
        }
      }
      return list;
    },
    onAreaSelect(code, area) {
      this.areaSelected = {};
      this.areaLabel = area;
      this.areaPopupValue.push({
        code: code,
        name: area
      });
      let level = this.$helper.len(this.areaPopupValue);
      if (level === 1) {
        this.areaPopupList = {
          ...this.fetchCityList(code)
        };
      } else if (level === 2) {
        this.areaShow = false;
        this.$emit('callback', this.areaPopupValue);
      } else if (level > 2) {
        let first = {
          ...this.areaPopupValue[0]
        };
        this.areaPopupValue = [first];
        this.areaPopupValue.push({
          code: code,
          name: area
        });
        this.areaShow = false;
        this.$emit('callback', this.areaPopupValue);
      }
      if (typeof this.areaSelected[code] !== 'undefined') {
        delete this.areaSelected[code];
        this.$forceUpdate();
        return;
      }
      this.areaSelected[code] = area;
      this.$forceUpdate();
    },
    reSelect() {
      this.areaSelected = {};
      this.areaPopupValue = [];
      this.areaLabel = '选择地区';
      this.areaPopupList = {
        ...this.areaList.province_list
      };
    }
  }
};
exports.default = _default;