"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.adPlace ? _c("van-swipe", {
    staticClass: "my-swipe",
    attrs: {
      autoplay: _vm.autoplay,
      "indicator-color": "white"
    }
  }, _vm._l(_vm.adPlace.ad_list, function (item) {
    return _c("van-swipe-item", {
      key: item.id,
      on: {
        click: function ($event) {
          return _vm.swipperClick(item);
        }
      }
    }, [_c("img", {
      attrs: {
        src: item.content,
        width: "100%"
      }
    })]);
  }), 1) : _vm._e();
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;