"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "area-selector"
  }, [_vm.$helper.len(_vm.areaPopupList) > 0 ? _c("div", {
    staticClass: "popup-area-container"
  }, [_c("div", {
    staticClass: "popup-area-header"
  }, [_c("span", {
    staticClass: "area-label"
  }, [_vm._v(_vm._s(_vm.areaLabel))]), _c("span", {
    staticClass: "fr",
    on: {
      click: _vm.reSelect
    }
  }, [_vm._v("重新选择")])]), _c("ul", {
    staticClass: "popup-area"
  }, _vm._l(_vm.areaPopupList, function (area, code) {
    return _c("li", {
      class: {
        selected: typeof _vm.areaSelected[code] !== "undefined"
      },
      on: {
        click: function ($event) {
          return _vm.onAreaSelect(code, area);
        }
      }
    }, [_vm._v(" " + _vm._s(area) + " ")]);
  }), 0)]) : _c("div", {
    staticClass: "area-loading"
  }, [_c("van-loading", {
    attrs: {
      color: "#dd7e6b"
    }
  })], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;