"use strict";

var _interopRequireDefault = require("/www/wwwroot/zhaopin.quanyoukeji.com/web/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _main = _interopRequireDefault(require("@/main"));
var _validate = require("@/utils/validate");
var _vant = require("vant");
var _storage = _interopRequireDefault(require("@/utils/storage"));
var _store = _interopRequireDefault(require("@/store"));
var _default = {
  /**
   * 获取长度
   * @param data
   * @returns {number|*}
   */
  len(data) {
    if (!data || typeof data === 'undefined') {
      return 0;
    }
    if (typeof data === 'string' || data instanceof Array) {
      return data.length;
    }
    if (typeof data === 'object') {
      if (typeof data['length'] !== 'undefined') {
        return data.length;
      }
      return Object.keys(data).length;
    }
    return 0;
  },
  strPad(str, maxLen = 3, pad = '0', type = 'left') {
    if (!str) {
      return str;
    }
    if (typeof str !== 'string') {
      str = str.toString();
    }
    let len = str.length;
    if (len < maxLen) {
      if (type === 'left') {
        return str.padStart(maxLen, pad);
      } else {
        return str.padEnd(maxLen, pad);
      }
    }
    return str;
  },
  loading(vue, msg) {
    if (!vue) {
      vue = _main.default;
    }
    vue.$store.dispatch('loading/show', {
      text: msg || '加载中...',
      color: '#dd7e6b'
    });
  },
  loadingClose(vue) {
    if (!vue) {
      vue = _main.default;
    }
    vue.$store.dispatch('loading/hide');
  },
  success(msg, useNotify = false) {
    if (useNotify) {
      (0, _vant.Notify)({
        type: 'success',
        message: msg
      });
    } else {
      _vant.Toast.success(msg);
    }
  },
  fail(msg, useNotify = false) {
    if (useNotify) {
      (0, _vant.Notify)({
        type: 'warning',
        message: msg
      });
    } else {
      _vant.Toast.fail(msg);
    }
  },
  alert(msg, callback = null, isSuccess = false) {
    return _vant.Dialog.alert({
      title: '提示',
      message: msg,
      theme: 'round-button',
      confirmButtonColor: !isSuccess ? '#ee0a24' : '#07c160'
    }).then(() => {
      if (callback && typeof callback === 'function') {
        callback();
      }
    });
  },
  /**
   *
   * @param arr ex.['/user/*','/msg/*']
   * @param str ex. '/user/index/aaa,'/msg/index/add'
   * @returns {*|*[]}
   */
  wildcardMath(arr, str) {
    // If there are no items in the array, return an empty array
    if (typeof arr === 'undefined' || arr.length === 0) return [];
    // If the string is empty return all items in the array
    if (typeof str === 'undefined' || str.length === 0) return arr;

    // Create a new array to hold the results.
    var res = [];

    // Check where the start (*) is in the string
    var starIndex = str.indexOf('*');

    // If the star is the first character...
    if (starIndex === 0) {
      // Get the string without the star.
      str = str.substr(1);
      for (var i = 0; i < arr.length; i++) {
        // Check if each item contains an indexOf function, if it doesn't it's not a (standard) string.
        // It doesn't necessarily mean it IS a string either.
        if (!arr[i].indexOf) continue;

        // Check if the string is at the end of each item.
        if (arr[i].indexOf(str) === arr[i].length - str.length) {
          // If it is, add the item to the results.
          res.push(arr[i]);
        }
      }
    }
    // Otherwise, if the star is the last character
    else if (starIndex === str.length - 1) {
      // Get the string without the star.
      str = str.substr(0, str.length - 1);
      for (var i = 0; i < arr.length; i++) {
        // Check indexOf function
        if (!arr[i].indexOf) continue;
        // Check if the string is at the beginning of each item
        if (arr[i].indexOf(str) === 0) {
          // If it is, add the item to the results.
          res.push(arr[i]);
        }
      }
    }
    // In any other case...
    else {
      for (var i = 0; i < arr.length; i++) {
        // Check indexOf function
        if (!arr[i].indexOf) continue;
        // Check if the string is anywhere in each item
        if (arr[i].indexOf(str) !== -1) {
          // If it is, add the item to the results
          res.push(arr[i]);
        }
      }
    }

    // Return the results as a new array.
    return res;
  },
  genOauthUrl(vm = null, pageUrl = null) {
    var _store$getters$wxoa;
    const state = this.randomString();
    if (!pageUrl) {
      pageUrl = location.href;
    }
    if (pageUrl.indexOf('code=') !== -1) {
      let arr = pageUrl.split('code=');
      pageUrl = arr[0];
    }
    if (pageUrl.indexOf('state=') !== -1) {
      let arr = pageUrl.split('state=');
      pageUrl = arr[0];
    }
    if (pageUrl.indexOf('callback=') !== -1) {
      let arr = pageUrl.split('callback=');
      pageUrl = arr[0];
    }
    if (pageUrl.indexOf('?') !== -1) {
      pageUrl += '&callback=1';
    } else {
      pageUrl += '?callback=1';
    }
    pageUrl = encodeURIComponent(pageUrl);
    _storage.default.setOauthState(state);
    let appId = (_store$getters$wxoa = _store.default.getters.wxoa) === null || _store$getters$wxoa === void 0 ? void 0 : _store$getters$wxoa.app_id;
    if (!appId) {
      appId = process.env.VUE_APP_WX_APP_ID;
    }
    const wxOauthUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appId + '&redirect_uri=' + pageUrl + '&response_type=code&scope=snsapi_userinfo&state=' + state + '#wechat_redirect';
    return wxOauthUrl;
  },
  /**
   * 获取URL中的参数
   * @param vue
   * @param key
   * @returns {*}
   */
  getQuery(vue, key) {
    let params = vue.$route.params;
    if (!params || this.len(params) <= 0) {
      params = vue.$route.query;
      if (!params || this.len(params) <= 0) {
        return null;
      }
    }
    if (!key || typeof key === 'undefined') {
      key = 'id';
    }
    if (!params[key] || typeof params[key] === 'undefined') {
      return null;
    }
    return params[key];
  },
  randomString(e) {
    e = e || 32;
    let t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678',
      a = t.length,
      n = '';
    for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
    return n;
  },
  parseUrl(url) {
    const parser = document.createElement('a');
    parser.href = url;
    return parser;
  },
  trimChar(str, char, type) {
    if (char) {
      if (type === 'left') {
        return str.replace(new RegExp('^\\' + char + '+', 'g'), '');
      } else if (type === 'right') {
        return str.replace(new RegExp('\\' + char + '+$', 'g'), '');
      }
      return str.replace(new RegExp('^\\' + char + '+|\\' + char + '+$', 'g'), '');
    }
    return str.replace(/^\s+|\s+$/g, '');
  },
  asPrice(num) {
    let result = parseFloat(num);
    if (isNaN(result)) {
      return 0.0;
    }
    result = Math.round(num * 100) / 100;
    let s_x = result.toString();
    let pos_decimal = s_x.indexOf('.');
    if (pos_decimal < 0) {
      pos_decimal = s_x.length;
      s_x += '.';
    }
    while (s_x.length <= pos_decimal + 2) {
      s_x += '0';
    }
    return s_x;
  },
  isRightPrice(_keyword) {
    if (typeof _keyword === 'undefined' || _keyword === null || _keyword === '.' || _keyword === '0' || _keyword === '0.' || _keyword === '0.0' || _keyword === '0.00') {
      return false;
    }
    _keyword = _keyword.toString();
    let index = _keyword.indexOf('0'),
      length = _keyword.length;
    // 0开头的数字串
    if (index === 0 && length > 1) {
      return /^[0]{1}[.]{1}[0-9]{1,2}$/.test(_keyword);
    }
    // 非0开头的数字
    return /^[1-9]{1}[0-9]{0,10}[.]{0,1}[0-9]{0,2}$/.test(_keyword);
  },
  fixUploadUrl(url) {
    if (!url) return null;
    if (url.indexOf('data:image/') !== -1) {
      return url;
    }
    if (url.indexOf(':/') !== -1 || !url) {
      return url;
    }
    return process.env.VUE_APP_UPLOAD_FILE_URL + url;
  },
  removeHtml(str) {
    str = str.replace(/<[^>]+>/g, ''); //截取html标签
    str = str.replace(/&nbsp;/gi, ''); //截取空格等特殊标签
    return str;
  },
  nl2br(str, is_xhtml) {
    if (typeof str === 'undefined' || str === null) {
      return '';
    }
    let breakTag = is_xhtml || typeof is_xhtml === 'undefined' ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
  },
  int2ip(ipInt) {
    let str,
      tt = [];
    tt[0] = ipInt >>> 24 >>> 0;
    tt[1] = ipInt << 8 >>> 24 >>> 0;
    tt[2] = ipInt << 16 >>> 24;
    tt[3] = ipInt << 24 >>> 24;
    str = String(tt[0]) + '.' + String(tt[1]) + '.' + String(tt[2]) + '.' + String(tt[3]);
    return str;
  }
};
exports.default = _default;