"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.$helper.len(_vm.row) > 0 ? _c("div", {
    staticClass: "about"
  }, [_c("van-nav-bar", {
    attrs: {
      title: "内容详情",
      "left-text": "返回",
      "left-arrow": ""
    },
    on: {
      "click-left": _vm.onClickLeft,
      "click-right": _vm.onClickRight
    }
  }), _c("div", {
    staticClass: "article__header"
  }, [_c("h1", {
    staticClass: "article__title"
  }, [_vm._v(_vm._s(_vm.row.title))]), _c("span", {
    staticClass: "article_time"
  }, [_vm._v(_vm._s(_vm.timeHelper.dateTimeDisplay(_vm.row.created_at)))])]), _c("div", {
    staticClass: "article-container article-container-ios"
  }, [_c("article", {
    staticClass: "syl-article-base article syl-page-article syl-device-mobile"
  }, [_c("div", {
    staticClass: "content",
    domProps: {
      innerHTML: _vm._s(_vm.row.content)
    }
  })])])], 1) : _vm._e();
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;