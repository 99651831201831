"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "lst-ban"
  }, [_c("img", {
    attrs: {
      src: _vm.$helper.fixUploadUrl(_vm.col.cover)
    }
  }), _vm.col ? _c("h3", {
    staticClass: "pad-hor"
  }, [_vm._v(_vm._s(_vm.col.title))]) : _vm._e()]), _c("div", {
    staticClass: "Cont"
  }, [_vm.dataList.length > 0 ? _c("div", {
    staticClass: "van-list"
  }, _vm._l(_vm.dataList, function (row) {
    return _c("div", {
      staticClass: "van-cell"
    }, [_c("div", {
      staticClass: "van-cell__title"
    }, [_c("a", {
      attrs: {
        href: row.url
      }
    }, [_vm._v(_vm._s(row.title))])])]);
  }), 0) : _vm._e()])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;