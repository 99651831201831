"use strict";

var _interopRequireDefault = require("/www/wwwroot/zhaopin.quanyoukeji.com/web/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.error.cause.js");
var _collection = require("@/api/collection");
var _Swiper = _interopRequireDefault(require("@/components/Swiper.vue"));
var _default = {
  data() {
    return {
      colId: null,
      col: null,
      loading: false,
      dataList: [],
      page: 1,
      pageSize: 10,
      totalPage: 0,
      totalCount: 0
    };
  },
  components: {
    Swiper: _Swiper.default
  },
  async created() {
    this.$helper.loading(this);
    this.loading = true;
    this.colId = this.$helper.getQuery(this);
    if (!this.colId) {
      this.$helper.alert('合集id不能为空', () => {
        this.$router.go(-1);
      });
      return false;
    }
    await this.fetchCollectionDetail();
  },
  methods: {
    async fetchCollectionDetail(params = null) {
      let def = {
        id: this.colId,
        page: this.page,
        page_size: this.pageSize
      };
      if (params) {
        def = Object.assign(def, params);
      }
      try {
        const resp = await (0, _collection.getCollectionDetail)(def);
        this.$helper.loadingClose(this);
        this.loading = false;
        if (Number(resp.code) === 0) {
          const {
            data
          } = resp;
          if (data) {
            this.col = data.data;
            this.dataList.push(...data.data['relations']);
          } else {
            this.dataList = [];
            this.page = 1;
            this.pageSize = 10;
            this.totalCount = 0;
            this.totalPage = 0;
            return Promise.reject(new Error(resp['message']));
          }
        }
      } catch (e) {
        console.log(e);
        this.$helper.loadingClose(this);
        this.loading = false;
        this.dataList = [];
      }
    }
  }
};
exports.default = _default;