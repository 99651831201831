"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSettingApp = void 0;
var _request = require("@/utils/request");
const getSettingApp = params => {
  let url = '/setting/app';
  if (params) {
    return (0, _request.get)(url, params);
  }
  return (0, _request.get)(url);
};
exports.getSettingApp = getSettingApp;