"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _ad = require("@/api/ad");
var _validate = require("@/utils/validate");
var _default = {
  name: 'Swiper',
  data() {
    return {
      adPlace: null
    };
  },
  props: {
    adId: {
      type: Number,
      default() {
        return 391900343169029;
      }
    },
    autoplay: {
      type: Number,
      default() {
        return 3000;
      }
    }
  },
  async created() {
    await this.getAdPlace();
  },
  methods: {
    async getAdPlace() {
      (0, _ad.getAdPlaceInfo)({
        id: this.adId
      }).then(res => {
        const {
          data
        } = res;
        if (data) {
          this.adPlace = data.data_list;
        }
      });
    },
    swipperClick(row) {
      let link = row.link,
        linkType = Number(row['link_type']);
      if (linkType === 5 && (0, _validate.isExternalLink)(link)) {
        window.open(link);
      } else if (linkType === 1) {
        this.$router.push('/show?id=' + link);
      } else if (linkType === 2) {
        this.$router.push('/list');
      } else if (linkType === 3) {
        this.$router.push('/category?id=' + link);
      } else if (linkType === 6) {
        window.location.href = '/';
      }
    }
  }
};
exports.default = _default;