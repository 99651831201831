"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("van-grid", _vm._l(_vm.categoryList, function (item) {
    return _vm.$helper.len(_vm.categoryList) > 0 ? _c("van-grid-item", {
      key: item.id,
      attrs: {
        icon: _vm.$helper.fixUploadUrl(item.icon),
        text: item.cate_name
      },
      on: {
        click: function ($event) {
          return _vm.onCategoryClick(item);
        }
      }
    }) : _vm._e();
  }), 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;