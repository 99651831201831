"use strict";

var _interopRequireDefault = require("/www/wwwroot/zhaopin.quanyoukeji.com/web/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _storage = _interopRequireDefault(require("@/utils/storage"));
const tokenKey = 'accessToken';
const tokenHelper = {
  set: function (response, callback) {
    if (!response) {
      return false;
    }
    let timestamp = new Date().getTime() / 1000;
    let token = response.access_token,
      expire = Number(timestamp) + Number(response['expires_in'] - 100);
    _storage.default.set(tokenKey, token);
    _storage.default.set('accessTokenExpire', expire.toString());
    if (typeof response['refresh_token'] !== 'undefined') {
      let refresh_token = response['refresh_token'],
        refresh_token_expire = Number(response['refresh_token_expires_in']) + Number(timestamp);
      _storage.default.set('refreshToken', refresh_token);
      _storage.default.set('refreshTokenExpire', refresh_token_expire);
    }
    if (callback && typeof callback === 'function') {
      return callback(response);
    }
  },
  get: function () {
    let timestamp = new Date().getTime() / 1000,
      accessTokenExpire = _storage.default.get('accessTokenExpire');
    if (!accessTokenExpire || accessTokenExpire <= timestamp) {
      return null;
    }
    return _storage.default.get(tokenKey);
  },
  clean: function () {
    _storage.default.remove(tokenKey);
    _storage.default.remove('accessTokenExpire');
    _storage.default.clean();
  },
  getRefreshToken: function () {
    let timestamp = new Date().getTime() / 1000,
      refreshTokenExpire = _storage.default.get('refreshTokenExpire');
    if (!refreshTokenExpire || refreshTokenExpire <= timestamp) {
      return null;
    }
    return _storage.default.get('refreshToken');
  },
  isLogin: function () {
    let accessToken = tokenHelper.get();
    if (accessToken) {
      return true;
    }
    let refreshToken = tokenHelper.getRefreshToken();
    return !!refreshToken;
  }
};
var _default = tokenHelper;
exports.default = _default;