"use strict";

var _interopRequireDefault = require("/www/wwwroot/zhaopin.quanyoukeji.com/web/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _App = _interopRequireDefault(require("./App.vue"));
var _router = _interopRequireDefault(require("./router"));
var _store = _interopRequireDefault(require("./store"));
var _vant = _interopRequireDefault(require("vant"));
require("vant/lib/index.less");
var _helper = _interopRequireDefault(require("./utils/helper"));
require("remixicon/fonts/remixicon.css");
// 切记：为了能够覆盖默认的 less 变量，这里一定要后缀为 .less

_vue.default.use(_vant.default);
_vue.default.config.productionTip = false;
_vue.default.prototype.$helper = _helper.default;
const vm = new _vue.default({
  router: _router.default,
  store: _store.default,
  render: h => h(_App.default)
}).$mount('#app');
var _default = vm;
exports.default = _default;