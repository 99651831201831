"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCollectionList = exports.getCollectionDetail = void 0;
var _request = require("@/utils/request");
const getCollectionList = (params = null) => {
  return (0, _request.get)(`collection/index`, params);
};
exports.getCollectionList = getCollectionList;
const getCollectionDetail = params => {
  return (0, _request.get)(`collection/detail`, params);
};
exports.getCollectionDetail = getCollectionDetail;