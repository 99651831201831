"use strict";

var _interopRequireDefault = require("/www/wwwroot/zhaopin.quanyoukeji.com/web/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.error.cause.js");
var _area = require("@/api/area");
var _article = require("@/api/article");
var _Swiper = _interopRequireDefault(require("@/components/Swiper.vue"));
var _CategoryGrid = _interopRequireDefault(require("@/components/CategoryGrid.vue"));
var _AreaSelector = _interopRequireDefault(require("@/components/AreaSelector.vue"));
const DEFAULT_AREA_LIST = {
  province_list: {},
  city_list: {},
  county_list: {}
};
var _default = {
  data() {
    return {
      //areaList,
      areaList: {
        ...DEFAULT_AREA_LIST
      },
      categoryInfo: null,
      categoryExtend: [],
      cateId: 404253924814853,
      loading: false,
      finished: false,
      refreshing: false,
      areaShow: false,
      extShow: false,
      extActions: [],
      areaText: '',
      areaCode: '',
      filterValue: {},
      articleList: [],
      page: 1,
      pageSize: 10,
      totalPage: 0,
      totalCount: 0,
      currentExt: null,
      extendData: null,
      currentIdx: -1,
      selectExt: null,
      areaSelected: {},
      areaLabel: '选择地区',
      areaPopupList: {},
      areaPopupValue: []
    };
  },
  components: {
    Swiper: _Swiper.default,
    CategoryGrid: _CategoryGrid.default,
    AreaSelector: _AreaSelector.default
  },
  async created() {
    this.$helper.loading(this);
    this.loading = true;
    if (!this.cateId) {
      this.$helper.alert('分类id不能为空', () => {
        this.$router.go(-1);
      });
      return false;
    }
    await this.fetchAreaTree();
    await this.fetchCategory();
    await this.fetchArticle();
  },
  methods: {
    parseOptions(v) {
      if (!v.options) {
        return null;
      }
      let arr = v.options.split('\n'),
        result = [];
      arr.forEach(item => {
        let row = item.split('|');
        if (row[1] && (row[0] || Number(row[0]) === 0)) {
          result.push({
            value: row[0] + '',
            text: row[1]
          });
        }
      });
      return result;
    },
    async loadMore() {
      this.page += 1;
      this.$helper.loading(this);
      this.loading = true;
      await this.fetchArticle();
    },
    async onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.dataList = [];
      this.$helper.loading(this);
      this.loading = true;
      this.page = 1;
      await this.fetchArticle();
    },
    async fetchArticle(params = null) {
      let def = {
        cid: this.cateId,
        page: this.page,
        page_size: this.pageSize
      };
      // 修复加载更多后不带筛选条件的BUG
      let tmp = {};
      if (this.currentExt && this.selectExt) {
        let key = this.currentExt.field_name;
        tmp[key] = this.selectExt.value;
      }
      if (this.areaCode) {
        tmp['area_code'] = this.areaCode.split('/').pop();
        def = Object.assign(def, tmp);
      }
      if (params) {
        def = Object.assign(def, params);
      }
      try {
        const resp = await (0, _article.getArticleList)(def);
        this.$helper.loadingClose(this);
        this.loading = false;
        if (Number(resp.code) === 0) {
          const {
            data
          } = resp;
          if (data) {
            this.articleList.push(...data.data_list);
            this.page = data.current_page;
            this.pageSize = data.page_size;
            this.totalCount = data.total_count;
            this.totalPage = data.total_page;
            for (let i in this.articleList) {
              let url = this.articleList[i].url;
              if (!url) {
                this.articleList[i].url = '/show?id=' + this.articleList[i].id;
              }
            }
            this.refreshing = false;
            if (this.page >= this.totalPage || this.totalCount <= this.pageSize) {
              this.finished = true;
            } else {
              this.finished = false;
            }
          } else {
            this.articleList = [];
            this.page = 1;
            this.pageSize = 10;
            this.totalCount = 0;
            this.totalPage = 0;
            return Promise.reject(new Error(resp.message));
          }
        }
      } catch (e) {
        console.log(e);
        this.$helper.loadingClose(this);
        this.loading = false;
        this.finished = true;
        this.articleList = [];
        this.page = 1;
        this.totalPage = 0;
        this.totalCount = 0;
      }
    },
    async fetchCategory() {
      let params = {
        id: this.cateId
      };
      try {
        const resp = await (0, _article.getCategoryInfo)(params);
        if (Number(resp.code) === 0) {
          this.categoryExtend = [{
            field_name: 'area_code',
            label: '地区',
            show_label: '',
            options: [],
            cls: {
              'van-dropdown-menu__title': true
            }
          }];
          this.categoryInfo = resp.data;
          let extend = this.categoryInfo.extends;
          if (this.$helper.len(extend) > 0) {
            let options;
            extend.forEach((v, i) => {
              extend[i]['cls'] = {
                'van-dropdown-menu__title': true
              };
              extend[i]['show_label'] = '';
              if (v.options) {
                options = this.parseOptions(v);
                extend[i]['options'] = options;
              }
              this.categoryExtend.push(extend[i]);
            });
          }
          // 默认选中
          for (let i in this.categoryExtend) {
            this.filterValue[this.categoryExtend[i].field_name] = -1 + '';
          }
        } else {
          return Promise.reject(new Error(resp.message));
        }
      } catch (e) {
        this.$toast.fail(e.message);
      }
    },
    async fetchAreaTree() {
      (0, _area.getAreaTree)({
        level: 1,
        parent: 0
      }).then(res => {
        const {
          data
        } = res;
        this.areaList = {
          ...DEFAULT_AREA_LIST
        };
        let area, child;
        for (let i in data.data_list) {
          area = data.data_list[i];
          this.areaList.province_list[area.code] = area.name;
          if (this.$helper.len(area.children) > 0) {
            let prefix = area.code.toString().substring(0, 3);
            this.areaList.city_list[prefix] = '全部';
            for (let c in area.children) {
              child = area.children[c];
              this.areaList.city_list[child.code] = child.name;
            }
          }
        }
      });
    },
    filterClick(extend, idx) {
      this.currentExt = extend;
      this.currentIdx = idx;
      if (extend.field_name === 'area_code') {
        this.showArea();
      } else {
        this.extShow = true;
        this.extActions = [...extend.options];
        this.extendData = extend;
      }
      let cls = {
        ...extend.cls
      };
      cls['van-dropdown-menu__title--active'] = true;
      cls['van-dropdown-menu__title--down'] = true;
      this.$set(extend, 'cls', cls);
    },
    showArea() {
      this.areaPopupList = {
        ...this.areaList.province_list
      };
      this.areaSelected = {};
      this.areaPopupValue = [];
      this.areaShow = true;
    },
    onAreaCancel() {
      this.areaShow = false;
      this.initCurrentExt();
    },
    onExtCancel() {
      this.extShow = false;
      this.initCurrentExt();
    },
    initCurrentExt() {
      this.$set(this.currentExt, 'cls', {
        'van-dropdown-menu__title': true
      });
      this.$set(this.currentExt, 'show_label', null);
    },
    onExtConfirm(v) {
      if (this.currentExt) {
        this.currentExt.show_label = v.text;
        this.$set(this.currentExt, 'show_label', v.text);
      }
      this.selectExt = {
        ...v
      };
      let params = {};
      let key = this.currentExt.field_name;
      params[key] = v.value;
      this.articleList = [];
      this.extShow = false;
      this.page = 1;
      this.fetchArticle(this.formatQuery(params));
    },
    onAreaConfirm(val) {
      this.areaText = '';
      this.areaCode = '';
      for (let i in val) {
        this.areaText += val[i].name + ' > ';
        this.areaCode += val[i].code + '/';
      }
      this.areaText = this.areaText.substring(0, this.areaText.length - 3);
      this.areaCode = this.areaCode.substring(0, this.areaCode.length - 1);
      this.areaShow = false;
      this.$set(this.categoryExtend[0], 'show_label', this.areaText);
      this.articleList = [];
      let params = {};
      if (this.areaCode !== '') {
        params['area_code'] = this.areaCode.split('/').pop();
      }
      this.page = 1;
      this.fetchArticle(this.formatQuery(params));
    },
    formatQuery(params) {
      if (!params) {
        params = [];
      }
      if (this.$helper.len(this.selectExt) > 0 && this.$helper.len(this.extendData) > 0) {
        let tmp = {};
        let key = this.extendData.field_name;
        tmp[key] = this.selectExt.value;
        if (this.$helper.len(tmp) > 0) {
          params['extend'] = JSON.stringify(tmp);
        }
      }
      return params;
    }
  }
};
exports.default = _default;