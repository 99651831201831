"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  namespaced: true,
  state: {
    show: false,
    text: null,
    color: null
  },
  mutations: {
    SHOW(state) {
      state.show = true;
    },
    HIDE(state) {
      state.show = false;
    },
    TEXT(state, data) {
      state.text = data;
    },
    COLOR(state, color) {
      state.color = color;
    }
  },
  actions: {
    show({
      commit
    }, data) {
      if (data) {
        if (data.text) commit('TEXT', data.text);
        if (data.color) commit('COLOR', data.color);
      }
      commit('SHOW');
    },
    hide({
      commit
    }) {
      commit('HIDE');
    }
  }
};
exports.default = _default;