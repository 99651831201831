"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  namespaced: true,
  state: {
    active: 0 // 0=home,1=msgList,2=msgAdd,3=user
  },

  mutations: {
    ACTIVE(state, data) {
      state.active = data;
    }
  },
  actions: {
    change({
      commit
    }, data) {
      commit('ACTIVE', data);
    }
  }
};
exports.default = _default;