"use strict";

var _interopRequireDefault = require("/www/wwwroot/zhaopin.quanyoukeji.com/web/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _nprogress = _interopRequireDefault(require("nprogress"));
require("nprogress/nprogress.css");
/**
 * 重写路由的push方法
 */
const originalPush = _vueRouter.default.prototype.push;
_vueRouter.default.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  let caller = originalPush.call(this, location);
  if (caller && typeof caller.catch !== 'undefined') {
    return caller.catch(err => err);
  }
  return originalPush.call(this, location);
};
_vue.default.use(_vueRouter.default);
const need = require('./_import_' + process.env.NODE_ENV); // 获取组件的方法
const constantRoutes = [{
  path: '/',
  component: need('/HomeView'),
  name: 'index',
  meta: {
    title: '首页'
  }
}, {
  path: '/category',
  name: 'category',
  component: need('/CategoryView'),
  meta: {
    title: '分类'
  }
}, {
  path: '/collection',
  name: 'collection',
  component: need('/CollectionView'),
  meta: {
    title: '合集'
  }
}, {
  path: '/collection/show',
  name: 'collection.show',
  component: need('/CollectionShowView'),
  meta: {
    title: '合集'
  }
}, {
  path: '/list',
  name: 'list',
  component: need('/ListView'),
  meta: {
    title: '列表'
  }
}, {
  path: '/show',
  name: 'show',
  component: need('/ShowView'),
  meta: {
    title: '详情'
  }
},
// 修复无首页组件不显示404
{
  path: '',
  hidden: true
}, {
  path: '*',
  component: need('/404'),
  hidden: true,
  meta: {
    title: '页面未找到'
  }
}];
const router = new _vueRouter.default({
  mode: 'history',
  //base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    };
  },
  routes: constantRoutes
});
const formatPageTitle = pageTitle => {
  if (pageTitle) {
    return `${pageTitle} - ${process.env.VUE_APP_TITLE}`;
  }
  return process.env.VUE_APP_TITLE;
};
const setDocumentTitle = title => {
  document.title = title;
};
router.beforeEach((to, from, next) => {
  _nprogress.default.start();
  to.meta && typeof to.meta.title !== 'undefined' && setDocumentTitle(formatPageTitle(to.meta.title));
  next();
});
router.afterEach(() => {
  _nprogress.default.done(); // finish progress bar
});
var _default = router;
exports.default = _default;