"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const storage = {
  keyPrefix(key) {
    return process.env.VUE_APP_STORAGE_PREFIX + key;
  },
  get(key) {
    return localStorage.getItem(this.keyPrefix(key));
  },
  set(key, value) {
    let data;
    if (typeof value === 'string') {
      data = value;
    } else {
      data = JSON.stringify(value);
    }
    localStorage.setItem(this.keyPrefix(key), data);
  },
  remove(key) {
    return localStorage.removeItem(this.keyPrefix(key));
  },
  setPrivateKey: function (data) {
    return this.set('privateKey', data);
  },
  getPrivateKey: function () {
    return storage.get('privateKey');
  },
  removePrivateKey() {
    storage.remove('privateKey');
  },
  setUserId: function (id) {
    return this.set('userId', id);
  },
  getUserId() {
    return this.get('userId');
  },
  removeUserId() {
    this.remove('userId');
  },
  getOauthState() {
    return this.get('oauthState');
  },
  setOauthState(value) {
    this.set('oauthState', value);
  },
  removeOauthState() {
    this.remove('oauthState');
  },
  setReturnUrl(url) {
    this.set('returnUrl', url);
  },
  getReturnUrl() {
    return this.get('returnUrl');
  },
  removeReturnUrl() {
    return this.remove('returnUrl');
  },
  setAppData(data) {
    let timestamp = new Date().getTime() / 1000;
    let expire = Number(timestamp) + 120; // 有效期3分钟
    this.set('appDataExpire', expire.toString());
    this.set('appData', data);
  },
  getAppData() {
    let timestamp = new Date().getTime() / 1000,
      expire = this.get('appDataExpire');
    if (!expire || expire <= timestamp) {
      this.removeAppData();
      return null;
    }
    let data = this.get('appData');
    if (data) {
      return JSON.parse(data);
    }
    return null;
  },
  removeAppData() {
    return this.remove('appData');
  },
  setDraft(value, uid = 0) {
    if (!uid) {
      uid = this.getUserId();
    }
    if (!uid || !value) {
      return;
    }
    const key = 'messageDraft:' + uid;
    return this.set(key, value);
  },
  getDraft(uid = 0) {
    if (!uid) {
      uid = this.getUserId();
    }
    if (!uid) {
      return;
    }
    const key = 'messageDraft:' + uid;
    return this.get(key);
  },
  removeDraft(uid = 0) {
    if (!uid) {
      uid = this.getUserId();
    }
    if (!uid) {
      return;
    }
    const key = 'messageDraft:' + uid;
    return this.remove(key);
  },
  clean() {
    this.removePrivateKey();
    this.removeReturnUrl();
    this.removeUserId();
    this.removeOauthState();
    this.removeAppData();
    this.removeDraft();
  }
};
var _default = storage;
exports.default = _default;