"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("swiper"), _c("category-grid", {
    attrs: {
      "parent-id": _vm.cateId
    }
  }), _c("div", {
    staticClass: "blank"
  }), _vm.categoryExtend.length > 0 ? _c("div", {
    staticClass: "filter-box"
  }, _vm._l(_vm.categoryExtend, function (extend, idx) {
    return _c("div", {
      staticClass: "filter-item"
    }, [_c("span", {
      class: extend.cls,
      on: {
        click: function ($event) {
          return _vm.filterClick(extend, idx);
        }
      }
    }, [_vm._v(_vm._s(extend.show_label ? extend.show_label : extend.label))])]);
  }), 0) : _vm._e(), _vm.$helper.len(_vm.articleList) > 0 ? _c("van-pull-refresh", {
    on: {
      refresh: _vm.onRefresh
    },
    model: {
      value: _vm.refreshing,
      callback: function ($$v) {
        _vm.refreshing = $$v;
      },
      expression: "refreshing"
    }
  }, [_c("div", {
    staticClass: "van-list"
  }, _vm._l(_vm.articleList, function (row) {
    return _c("div", {
      staticClass: "van-cell"
    }, [_c("div", {
      staticClass: "van-cell__title"
    }, [_c("a", {
      attrs: {
        href: row.url
      }
    }, [_vm._v(_vm._s(row.title))]), _c("span", {
      staticStyle: {
        "padding-left": "5px"
      }
    }, [_vm._v(_vm._s(row.area))])])]);
  }), 0), !_vm.finished ? _c("div", {
    staticClass: "load-more text-center",
    on: {
      click: _vm.loadMore
    }
  }, [_vm.loading ? _c("van-loading", {
    attrs: {
      size: "24px"
    }
  }, [_vm._v("加载中...")]) : [_vm._v("点击加载更多...")]], 2) : _c("div", {
    staticClass: "load-more text-center"
  }, [_vm._v("没有更多了")])]) : _vm._e(), _c("van-action-sheet", {
    attrs: {
      "close-on-click-overlay": false
    },
    model: {
      value: _vm.areaShow,
      callback: function ($$v) {
        _vm.areaShow = $$v;
      },
      expression: "areaShow"
    }
  }, [!_vm.loading ? _c("van-area", {
    attrs: {
      title: "选择地区",
      "area-list": _vm.areaList,
      "columns-num": 2
    },
    on: {
      confirm: _vm.onAreaConfirm,
      cancel: _vm.onAreaCancel
    }
  }) : _vm._e()], 1), _c("van-action-sheet", {
    attrs: {
      "close-on-click-overlay": false
    },
    model: {
      value: _vm.extShow,
      callback: function ($$v) {
        _vm.extShow = $$v;
      },
      expression: "extShow"
    }
  }, [_c("van-picker", {
    attrs: {
      title: "选择考试类型",
      "show-toolbar": "",
      columns: _vm.extActions
    },
    on: {
      confirm: _vm.onExtConfirm,
      cancel: _vm.onExtCancel
    }
  })], 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;