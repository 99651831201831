"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAreaTree = void 0;
var _request = require("@/utils/request");
/**
 * 暂时废弃
 */
const getAreaTree = (params = null) => {
  return (0, _request.get)(`area/tree`, params);
};
exports.getAreaTree = getAreaTree;